<template>
  <div
    :class="className"
    :datas="datas"
    :title="title"
    :id="id"
    :style="{ height: height, width: width }"
    :labelfontsize="labelfontsize"
  ></div>
</template>

<script>
import echarts from "echarts";
import resize from "./mixins/resize";
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart",
    },
    id: {
      type: String,
      default: "chart",
    },
    //宽高
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "300px",
    },
    //标题
    title: {
      type: String,
      default: "",
    },
    //显示线条
    show: {
      type: String,
      default: "false",
    },
    //圈内部字体
    labelfontsize: {
      type: Number,
      default: 15,
    },
    //数据
    datas: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    datas() {
      this.initChart();
    },
  },
  data() {
    return {
      echart: null,
    };
  },
  created() {
    this.initChart();
  },
  methods: {
    initChart() {
      let that = this;
      let option = {
        //头顶标题
        title: {
          // text: '答题人数(人)',
          left: "center",
          top: 10,
          show: true,
          textStyle: {
            color: "rgb(120,120,120)",
            fontSize: "20px",
          },
        },
        //鼠标放上去的悬浮窗
        tooltip: {
          trigger: "item",
          formatter: "{b} :<br/> {c} ({d}%)",
        },
        //分类  块
        // legend: {
        //   top: "5%",
        //   left: "center",
        // },
        //图形设置
        series: [
          {
            name: this.title,
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                //内部显示
                // formatter: "{b}:{c}" + "\n\r" + "({d}%)",
                show: true,
                fontSize: this.labelfontsize,
                fontWeight: "bold",
              },
            },
            labelLine: {
              //指示线条
              show: this.show,
            },
            //数据
            data: this.datas,
          },
        ],
      };
      this.$nextTick(() => {
        this.echart = echarts
          .init(document.getElementById(this.id))
          .setOption(option);
      });
    },
  },
};
</script>

<style></style>
