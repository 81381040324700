<template>
  <div class="result">
    <div class="result result-box">
      <div class="result-top-left">
        <div class="result-top-left-title-font" style="font-size:16px">
          {{ testpaper.Title }}
        </div>
        <div
          class="result-top-left-title-font"
          style="font-size:12px;margin-top:5px;line-height:15px;color:rgb(120,120,120)"
        >
          {{ testpaper.Explanation }}
        </div>
      </div>
      <div class="result-top-left result-top-right">
        <div class="result-top-right-people">问卷调查人数</div>
        <div class="result-top-right-peoplenum">
          {{ testpaper.MemberCount }}人
        </div>
      </div>
    </div>
    <div class="result result-box" style="margin-top:10px;padding-left:15px">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="基本图表" name="first" v-loading="firstloading">
          <div
            v-if="
              testpaper.QuestionnaireResultTopicList !== undefined &&
                testpaper.QuestionnaireResultTopicList.length > 0
            "
          >
            <div
              v-for="(item, index) in testpaper.QuestionnaireResultTopicList"
              :key="index"
            >
              <div class="testpaper-title">
                ({{ differentiate(item.TopicType) }}) {{ index + 1 }}:
                {{ item.TopicDescription }}
              </div>
              <div class="testpaper-title textpaper-pictitle">
                ({{ differentiate(item.TopicType) }}) {{ index + 1 }}:
                {{ item.TopicDescription }}
              </div>
              <piechart
                :id="'pie' + index"
                :datas="item.QuestionnaireTopicOptionSubmitList"
                style="margin:0px auto"
              ></piechart>
              <!-- 表格 -->
              <div class="table-box">
                <el-table :data="item.QuestionnaireTopicOptionSubmitList">
                  <el-table-column prop="name" label="选项"> </el-table-column>
                  <el-table-column prop="value" label="选择人数" sortable>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
          <div
            v-if="
              testpaper.QuestionnaireResultTopicList == undefined ||
                testpaper.QuestionnaireResultTopicList.length <= 0
            "
            style="width:100%;font-size:14px;text-align:center;margin:20px auto"
          >
            暂无数据
          </div>
          <!-- 分页 -->
          <!-- <div style="text-align:right;margin:5px 10px" v-if="testpaper.QuestionnaireResultTopicList.length > 0">
            <el-pagination
              @size-change="basicSizeChange"
              @current-change="basicCurrentChange"
              :current-page="basicpage"
              :page-sizes="[5, 10, 15, 20]"
              :page-size="basicsize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="basictotal"
            >
            </el-pagination>
          </div> -->
        </el-tab-pane>
        <el-tab-pane
          label="问卷详情"
          name="second"
          :data="testpaper.options"
          v-loading="secondloading"
        >
          <div v-if="tablelist != undefined && tablelist.length > 0">
            <buttonPermissions :datas="'resultexport'">
              <el-button id="export" @click="exportanswer">导出</el-button>
            </buttonPermissions>
          </div>
          <div
            class="no-tabledata"
            v-if="tablelist == undefined || tablelist.length <= 0"
          >
            <div class="no-tabledata-font">暂无数据</div>
          </div>
          <!-- 表格 -->
          <el-table
            :header-row-style="headstyle"
            :cell-style="cellstyle"
            :data="tablelist"
            style="margin-top:15px"
            v-if="tablelist != undefined && tablelist.length > 0"
          >
            <el-table-column
              prop="WxNickname"
              label="提交人"
              width="100"
              show-overflow-tooltip
            >
              <!-- <template slot-scope="scope"> -->
              <!-- <el-tooltip
                  effect="dark"
                  :content="scope.row.WxNickname"
                  placement="top-start"
                  style="margin-left:0px"
                >
                  <div class="table-name">{{ scope.row.WxNickname }}</div>
                </el-tooltip> -->
              <!-- </template> -->
            </el-table-column>
            <el-table-column
              prop="AddTime"
              label="提交时间"
              width="150"
            ></el-table-column>
            <!-- 答案根据与题目的id匹配 并显示相应的内容 -->
            <el-table-column
              width="300"
              :render-header="renderheader"
              :label="v.TopicDescription"
              v-for="(v, i) in question"
              :key="i"
              :span="1"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <!-- {{scope.row.MemberQuestionnaireDetailPageListDtoList}} -->
                <!-- [0,1,6].includes(matchingId(scope.row.MemberQuestionnaireDetailPageListDtoList, v.Id).TopicType) -->
                <!-- <div
                  class="table-name"
                  v-if="
                    screening(
                      matchingIdarr(
                        scope.row.MemberQuestionnaireDetailPageListDtoList,
                        v.Id
                      ),
                      [1]
                    )
                  "
                >
                  {{
                    matchingmuchId(
                      matchingIdarr(
                        scope.row.MemberQuestionnaireDetailPageListDtoList,
                        v.Id
                      )
                    )
                  }}
                </div> -->
                <!-- 单选 下拉 -->
                <div class = "table-name" v-if ="matchingId(scope.row.MemberQuestionnaireDetailPageListDtoList, v.Id) != undefined && [0,6].includes(matchingId(scope.row.MemberQuestionnaireDetailPageListDtoList, v.Id).TopicType)">
                  {{ matchingId(scope.row.MemberQuestionnaireDetailPageListDtoList, v.Id).OptionDescription }}
                </div>
                <!-- 多选 -->
                <div class = "table-name" v-else-if="matchingIdarr(scope.row.MemberQuestionnaireDetailPageListDtoList, v.Id) != undefined && 
                matchingIdarr(scope.row.MemberQuestionnaireDetailPageListDtoList, v.Id).find((v)=>v.TopicType == 1)
                ">
                  {{matchingmuchId(matchingIdarr(scope.row.MemberQuestionnaireDetailPageListDtoList, v.Id))}}
                </div>
                <!-- 打分 -->
                <div
                  class="table-name"
                  v-else-if="
                    matchingId(scope.row.MemberQuestionnaireDetailPageListDtoList, v.Id) != undefined && [2,3].includes(matchingId(scope.row.MemberQuestionnaireDetailPageListDtoList, v.Id).TopicType)"
                >
                  {{
                    matchingId(
                      scope.row.MemberQuestionnaireDetailPageListDtoList,
                      v.Id
                    ).Score
                  }}
                </div>
                <!-- 只展示两张图片 -->
                <div
                  class="table-name"
                  v-else-if="matchingId(scope.row.MemberQuestionnaireDetailPageListDtoList, v.Id) != undefined && [4].includes(matchingId(scope.row.MemberQuestionnaireDetailPageListDtoList, v.Id).TopicType)"
                >
                <el-image
                    v-for="(src, index) in matchingId(
                      scope.row.MemberQuestionnaireDetailPageListDtoList,
                      v.Id
                    ).ImgUrls"
                    :key="index"
                    :src="imgUrl + src"
                    style="width:60px;height:60px;margin-right:10px"
                    v-if="index < 2"
                    @click="
                      clickimg(
                        matchingId(
                          scope.row.MemberQuestionnaireDetailPageListDtoList,
                          v.Id
                        ).ImgUrls
                      )
                    "
                    :preview-src-list="imglist"
                  ></el-image>
                </div>
                <!-- 手机 开放 -->
                <div
                  class="table-name"
                  v-else-if="matchingId(scope.row.MemberQuestionnaireDetailPageListDtoList, v.Id) != undefined && [5,7].includes(matchingId(scope.row.MemberQuestionnaireDetailPageListDtoList, v.Id).TopicType)"
                >
                  {{
                    matchingId(
                      scope.row.MemberQuestionnaireDetailPageListDtoList,
                      v.Id
                    ).Text
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="150">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="querymsg(scope)"
                  >查看</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div
            style="text-align:right;margin:5px 10px"
            v-if="tablelist != undefined && tablelist.length > 0"
          >
            <el-pagination
              @size-change="detailsSizeChange"
              @current-change="detailsCurrentChange"
              :current-page="detailspage"
              :page-sizes="[5, 10, 15, 20]"
              :page-size="detailssize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="detailstotal"
            >
            </el-pagination>
          </div>
          <el-dialog
            :visible.sync="dialogVisible"
            title="答卷详情"
            custom-class="customclass"
          >
            <div class="dialog-submitter">
              提交人:
              <span style="color:rgb(88,171,255)">{{
                nowsubmitter.WxNickname
              }}</span>
            </div>
            <div class="dialog-submitter" style="margin-top:5px">
              提交时间: {{ nowsubmitter.AddTime }}
            </div>
            <div class="dialog-table">
              <div
                class="dialog-table-row"
                v-for="(item, index) in question"
                :key="index"
              >
                <div class="dialog-table-row-font" style=" font-weight: 600;">
                  {{ index + 1 }}.{{ item.TopicDescription }}
                </div>
                <div
                  v-if="nowsubmitter.MemberQuestionnaireDetailPageListDtoList"
                >
                  <!-- {{matchingId(nowsubmitter.MemberQuestionnaireDetailPageListDtoList,item.Id)}} -->
                  <!-- 单选 下拉 -->
                <div class = "table-name" v-if ="matchingId(nowsubmitter.MemberQuestionnaireDetailPageListDtoList,item.Id) != undefined && [0,6].includes(matchingId(nowsubmitter.MemberQuestionnaireDetailPageListDtoList,item.Id).TopicType)">
                  {{ matchingId(nowsubmitter.MemberQuestionnaireDetailPageListDtoList,item.Id).OptionDescription }}
                </div>
                <!-- 多选 -->
                <div class = "table-name" v-else-if="matchingIdarr(nowsubmitter.MemberQuestionnaireDetailPageListDtoList,item.Id) != undefined && 
                matchingIdarr(nowsubmitter.MemberQuestionnaireDetailPageListDtoList,item.Id).find((v)=>v.TopicType == 1)
                ">
                  {{matchingmuchId(matchingIdarr(nowsubmitter.MemberQuestionnaireDetailPageListDtoList,item.Id))}}
                </div>
                <!-- 打分 -->
                <div
                  class="table-name"
                  v-else-if="
                    matchingId(nowsubmitter.MemberQuestionnaireDetailPageListDtoList,item.Id) != undefined && [2,3].includes(matchingId(nowsubmitter.MemberQuestionnaireDetailPageListDtoList,item.Id).TopicType)"
                >
                  {{
                    matchingId(
                      nowsubmitter.MemberQuestionnaireDetailPageListDtoList,item.Id
                    ).Score
                  }}
                </div>
                <!-- 只展示两张图片 -->
                <div
                  class="table-name"
                  v-else-if="matchingId(nowsubmitter.MemberQuestionnaireDetailPageListDtoList,item.Id) != undefined && [4].includes(matchingId(nowsubmitter.MemberQuestionnaireDetailPageListDtoList,item.Id).TopicType)"
                >
                <el-image
                    v-for="(src, index) in matchingId(
                     nowsubmitter.MemberQuestionnaireDetailPageListDtoList,item.Id
                    ).ImgUrls"
                    :key="index"
                    :src="imgUrl + src"
                    style="width:60px;height:60px;margin-right:10px"
                    v-if="index < 2"
                    @click="
                      clickimg(
                        matchingId(
                         nowsubmitter.MemberQuestionnaireDetailPageListDtoList,item.Id
                        ).ImgUrls
                      )
                    "
                    :preview-src-list="imglist"
                  ></el-image>
                </div>
                <!-- 手机 开放 -->
                <div
                  class="table-name"
                  v-else-if="matchingId(nowsubmitter.MemberQuestionnaireDetailPageListDtoList,item.Id) != undefined && [5,7].includes(matchingId(nowsubmitter.MemberQuestionnaireDetailPageListDtoList,item.Id).TopicType)"
                >
                  {{
                    matchingId(
                     nowsubmitter.MemberQuestionnaireDetailPageListDtoList,item.Id
                    ).Text
                  }}
                </div>
                </div>
              </div>
            </div>
          </el-dialog>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import buttonPermissions from "@/components/buttonPermissions";
import axios from "axios";
import config from "@/config/index";
import {
  getquestionresult,
  getquestiontitle,
  getanswer,
  exportlist,
} from "@/api/TurnTomySelf";
import piechart from "@/components/Charts/thepie";
export default {
  components: {
    piechart,
    buttonPermissions,
  },
  data() {
    return {
      activeName: "first",
      exportUrl: config.EXPORT_URL,
      imgUrl: config.IMG_BASE,
      firstloading: false,
      secondloading: false,
      //基本图表
      testpaper: [],

      basicpage: 1,
      basicsize: 10,
      basictotal: null,

      //题目
      question: [],
      //问卷详情
      tablelist: [],

      detailspage: 1,
      detailssize: 10,
      detailstotal: null,

      imglist: [], //查看大图
      dialogVisible: false, //弹出层
      nowsubmitter: {}, //当前查看的信息
    };
  },
  created() {
    // console.log(this.$route.query)
    this.gettestpaper();
    // this.getthetitlelist();
    // this.getanswerlist();
  },
  methods: {
    handleClick(e) {
      // console.log(e.name);
      if (e.name == "first") {
        this.gettestpaper();
      } else if (e.name == "second") {
        this.getthetitlelist();
        this.getanswerlist();
      }
    },
    //定义label表头
    renderheader(h, { column }) {
      return h(
        "el-tooltip",
        {
          props: {
            effect: "dark",
            content: `${column.label}`,
            placement: "top-start",
          },
        },
        [
          h(
            "div",
            {
              style:
                "height: 20px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;",
            },
            column.label
          ),
        ]
      );
    },
    //表格表头给背景色 不然会有透视
    //表头样式
    headstyle() {
      return "background:rgb(240,240,240)";
    },
    //表格样式
    cellstyle() {
      return "background:white";
    },
    //查看详情
    querymsg(e) {
      // console.log(e);
      this.dialogVisible = true;
      this.nowsubmitter = e.row;
      // console.log(this.nowsubmitter);
    },

    //基本图表每页条数
    basicSizeChange(e) {
      this.basicsize = e;
      this.getanswerlist();
    },
    //基本图表页码
    basicCurrentChange(e) {
      this.basicpage = e;
      this.getanswerlist();
    },
    //问卷详情每页条数
    detailsSizeChange(e) {
      // let a = Math.ceil(parseInt(this.detailstotal) / parseInt(e));
      // if (a < this.detailspage) {
      //   this.detailspage = a;
      // }
      this.detailspage = 1;
      this.detailssize = e;
      // console.log('当前条数',e)
      this.getthetitlelist();
      this.getanswerlist();
    },
    //问卷详情图表页码
    detailsCurrentChange(e) {
      // console.log('当前页码',e)
      this.detailspage = e;
      this.getthetitlelist();
      this.getanswerlist();
    },
    //获取当前行的图片路径并加上域名
    clickimg(e) {
      let newarr = e.map((v) => {
        return (v = this.imgUrl.concat(v));
      });
      // console.log(newarr)
      this.imglist = newarr;
    },
    //导出
    async exportanswer() {
      // try {
      // console.log(this.$route.query.id)
      let result = await axios({
        url:
          this.exportUrl +
          `/pc/questionnaire/memberquestionnairedetailexport?QuestionnaireId=${this.$route.query.id}`,
        method: "get",
        responseType: "blob",
      });
      // let data = "\ufeff" + result.data;
      let blob = new Blob([result.data], {
        type: "application/vnd.ms-excel",
      });
      // let objectUrl = URL.createObjectURL(blob);
      // window.open(objectUrl);

      let a = document.createElement("a"); //创建一个<a></a>标签
      a.href = URL.createObjectURL(blob); // 将流文件写入a标签的href属性值
      a.download = "问卷详情.xlsx"; //设置文件名
      a.style.display = "none"; // 藏起a标签
      document.body.appendChild(a); // 将a标签追加到文档对象中
      a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
      a.remove(); // 一次性的，用完就删除a标签
    },
    //获取问卷结果
    async gettestpaper() {
      this.firstloading = true;
      try {
        let data = {
          QuestionnaireId: this.$route.query.id,
        };
        let result = await getquestionresult(data);
        // console.log(result);
        if (result.IsSuccess) {
          this.testpaper = result.Result;
          // console.log(this.testpaper);
          this.testpaper.QuestionnaireResultTopicList.forEach((v) => {
            v.QuestionnaireTopicOptionSubmitList = this.changeKey(
              v.QuestionnaireTopicOptionSubmitList,
              ["name", "value"]
            );
          });
        }
      } finally {
        this.firstloading = false;
      }
    },
    //改变数组的key
    changeKey(arr, key) {
      let newArr = [];
      arr.forEach((item) => {
        let newObj = {};
        for (var i = 0; i < key.length; i++) {
          newObj[key[i]] = item[Object.keys(item)[i]];
        }
        newArr.push(newObj);
      });
      return newArr;
    },
    //区分题目类型
    differentiate(num) {
      if (num == 0) {
        return "单选题";
      } else if (num == 1) {
        return "多选题";
      } else if (num == 2) {
        return "打分题1到5";
      } else if (num == 3) {
        return "打分题1到10";
      } else if (num == 4) {
        return "图片上传";
      } else if (num == 5) {
        return "开放题";
      } else if (num == 6) {
        return "下拉筛选";
      } else if (num == 7) {
        return "手机号";
      }
    },
    //获取题目列表
    async getthetitlelist() {
      this.secondloading = true;
      try {
        let data = {
          QuestionnaireId: this.$route.query.id,
        };
        let result = await getquestiontitle(data);
        if (result.IsSuccess) {
          this.question = result.Result;
          // console.log(this.question);
        }
      } finally {
        this.secondloading = false;
      }
    },
    //获取答题列表
    async getanswerlist() {
      this.secondloading = true;
      try {
        let data = {
          QuestionnaireId: this.$route.query.id,
          Skip: (this.detailspage - 1) * this.detailssize,
          Take: this.detailssize,
        };
        let result = await getanswer(data);
        if (result.IsSuccess) {
          this.tablelist = result.Result.Results;
          this.detailstotal = result.Result.Total;
          // console.log(this.tablelist);
          // this.tablelist.map((v)=>{
          //   let a = v.MemberQuestionnaireDetailPageListDtoList.map((x)=>{
          //     if(x.TopicType == 1){
          //       return x.
          //     }
          //   })
          //   return a
          // })
        }
      } finally {
        this.secondloading = false;
      }
    },
    //查找与题目Id相同的选项
    matchingId(arr, id) {
      let a = arr.find((v) => {
        return v.QuestionnaireTopicId == id;
      });
      // console.log(a)
      return a;
    },
    matchingIdarr(arr, id) {
      let a = arr.filter((v) => {
        return v.QuestionnaireTopicId == id;
      });
      // console.log(a)
      return a;
    },
    //筛选
    screening(arr, con) {
      let a = arr.map((v)=>{
          return v.TopicType == con
      })
      return a
    },

    usemap(arr) {
      let a = arr
        .map((v) => {
          return v;
        })
        .join(";");
      return a;
    },
    //查找多选
    matchingmuchId(arr) {
      let a = arr
        .map((v) => {
          if (v.IsOther) {
            return v.Text;
          } else {
            return v.OptionDescription;
          }
        })
        .join(";");
      return a;
    },
  },
};
</script>

<style>
.result {
  width: 100%;
  height: auto;
  /* background: white; */
}
.result-box {
  width: 100%;
  height: auto;
  border: 1px solid rgb(245, 245, 245);
  background: white;
  /* padding: 10px; */
  overflow: hidden;
}
.result-top-left {
  width: 80%;
  height: auto;
  /* border: 1px solid black; */
  float: left;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background: white;
}
.result-top-right {
  width: 20%;
  height: auto;
  background: white;
}
.result-top-left-title-font {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 20px;
}
.result-top-right-people {
  width: 100%;
  height: auto;
  /* border: 1px solid black; */
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  color: rgb(120, 120, 120);
}
.result-top-right-peoplenum {
  width: 100%;
  height: auto;
  color: rgb(72, 167, 100);
  margin-top: 15px;
  text-align: center;
  font-size: 20px;
}
.testpaper-title {
  width: calc(100% - 15px);
  height: auto;
  background: rgb(245, 245, 245);
  font-size: 13px;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.textpaper-pictitle {
  height: auto;
  width: 800px;
  /* border:1px solid black; */
  margin: 10px auto 0px auto;
  background: white;
}
.table-box {
  width: 800px;
  height: auto;
  border: 1px solid rgb(245, 245, 245);
  margin: 20px auto;
}
.no-tabledata {
  width: 100%;
  height: 50px;
  background: rgb(245, 245, 245);
  /* border:1px solid black; */
  overflow: hidden;
}
.no-tabledata-font {
  background: white;
  text-align: center;
  line-height: 50px;
  font-size: 14px;
  /* margin-top: 15px; */
}
.table-name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dialog-submitter {
  font-size: 14px;
}
.customclass {
  overflow: hidden;
}
.dialog-table {
  width: 100%;
  height: auto;
  max-height: 500px;
  border: 1px solid rgb(230, 230, 230);
  margin-top: 20px;
  overflow: hidden;
  overflow-y: auto;
}
.dialog-table-row {
  width: 100%;
  height: auto;
  border-bottom: 1px solid rgb(230, 230, 230);
  padding: 5px 10px;
}
.dialog-table-row:last-child {
  border-bottom: none;
}
.dialog-table-row-font {
  font-size: 14px;
  word-wrap: break-word;
  line-height: 20px;
}
.el-tooltip__popper {
  max-width: 500px;
}
.el-icon-circle-close {
  color: white;
}
.dialog-answer {
  line-height: 20px;
  margin-top: 6px;
}
.el-tooltip {
  margin-left: 0px;
}
</style>
